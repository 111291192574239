import Cookie from '@/lib/cookie';
import { MD5 } from '@/lib/crypto/md5';
import Global from '@/lib/global';

export enum DeviceType {
    Android,
    IOS
}

class ConfigurationService {
    private env: string;
    private namespaceHash?: string;
    private isLocalhostDebug: string | null;
    private isMobileDevDebug: boolean;

    constructor() {
        this.env = process.env.REACT_APP_ENVIRONMENT || 'TEST';
        this.namespaceHash = this.getNamespaceHash();
        this.isLocalhostDebug = Cookie.parse(
            document.cookie || '', ConfigurationService.LOCALHOST_PP_ENABLED_COOKIE
        );

        this.isMobileDevDebug =
            Global.LocalStorage.getItem(ConfigurationService.MOBILE_DEV_LS_ITEM) === '1';
    }

    static NAMESPACE_NAME_COOKIE = 'nsname';

    static LOCALHOST_PP_ENABLED_COOKIE = 'localhostpp';

    static COOKIE_AUTH_NAME = 'can';

    static MOBILE_DEV_LS_ITEM = 'mbdevlsitem';

    static jwtServer = 'ws://localhost:4445';

    private toHash = (ns: string): string => {
        if (ns.split('-').length > 0) {
            return MD5(ns).slice(0, 8);
        }

        return ns;
    };

    public getNamespace = () => {
        const value = Cookie.parse(
            document.cookie || '', ConfigurationService.NAMESPACE_NAME_COOKIE
        );

        if (value) {
            return value;
        }

        return undefined;
    };

    public getNamespaceHash = () => {
        return this.toHash(this.getNamespace() || '');
    };

    public isProd = (): boolean => this.env === 'PROD';

    public isDev = (): boolean => /localhost/.test(window.location.href);

    public isDevPlatform = (): boolean =>
        this.env === 'TEST' && window.location.origin.endsWith('indrive.dev');

    public isMobileDev = (): boolean => this.isMobileDevDebug;

    public getDeviceType = (): DeviceType | null => {
        const userAgent = window.navigator.userAgent;
        if (/iPad|iPhone|iPod/.test(userAgent)) {
            return DeviceType.IOS;
        }
        if (/android/i.test(userAgent)) {
            return DeviceType.Android;
        }

        return null;
    };

    public getIsDarkTheme = (): boolean => {
        return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    };

    public getPaymentProviderGraphQLApi = (): string =>
        `${this.getPaymentProviderHost()}/api/payment-provider`;

    private getNamespaceFromLocation = () => {
        return window.location.origin
            .split('.')[0]
            .split('-')[3];
    };

    public getPaymentProviderHost = (): string => {
        if (this.isDevPlatform()) {
            try {
                return `https://payment-provider-${this.getNamespaceFromLocation()}.indrive.dev`;
            } catch (e) { /** do nothing */ }
        }

        if (this.isLocalhostDebug) {
            return 'http://localhost:8089';
        }

        if (this.isDev()) {
            if (!this.namespaceHash) {
                console.error('No namespace hash');
            }

            return `https://payment-provider-${this.namespaceHash}.indrive.dev`;
        }

        // For debugging from device from Mobile Browser in same network
        // Use below command to share payment-provider from kube to outside
        // kubectl port-forward --address 0.0.0.0 -n $NAMESPACE service/payment-provider 8089:8080
        if (this.isMobileDev()) {
            return `http://${window.location.hostname}:8089`;
        }

        return process.env.REACT_APP_PAYMENT_PROVIDER_API || '';
    };
}

export default ConfigurationService;
