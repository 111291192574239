import { HintFilled } from '@indriver/mireska';
import { InputMask } from 'imask';
import React, { ChangeEvent, RefObject } from 'react';

import { GQLErrorType } from '@/lib/errors/graphql-error';
import { Countries } from '@/lib/locale/countries';
import {
    FormField,
    FormId,
    FormSection,
    FormValues
} from '@/lib/payments/forms';
import { getHintType, hasHint, HintType } from '@/lib/payments/forms/hints';
import {
    DOCUMENT_ID,
    EMAIL_ID,
    FULL_ADDRESS_ID,
    FULL_BILLING_ADDRESS_ID,
    PHONE_ID
} from '@/lib/payments/forms/indentificators';

import Validation, { ValidationError } from '../../lib/forms/validation';
import FieldStorage from '../../lib/payments/forms/storage';
import FormInput from '../../shared/ui/core/form-input';
import SectionForm from '../../shared/ui/domain/section-form';
import { AppData } from '../../singlepage/app';

import Hint from '../hint';

import * as UI from './ui';

import isRegularField = FieldStorage.isRegularField;

type FillFormFields = FormSection[];

type FillFormProps = {
    ref?: RefObject<FillForm>;
    country: Countries;
    savedDocuments: AppData['paymentInfo']['savedDocuments'];
    fields: FillFormFields;
};

type FillFormState = {
    hint?: HintType;
    fields: Record<
        FormField['id'],
        {
            value: string;
            error: ValidationError;
            inputMaskInstance?: InputMask<{ mask: any }>;
            readonly validators: FormField['validators'];
        }
    >;
};

class FillForm extends React.Component<FillFormProps, FillFormState> {
    constructor(props: FillFormProps) {
        super(props);

        this.state = {
            hint: undefined,
            fields: this.buildFieldsState(props.fields),
        };
    }

    shouldComponentUpdate = (nextProps: FillFormProps) => {
        if (this.props.fields !== nextProps.fields) {
            this.setState({
                hint: undefined,
                fields: this.buildFieldsState(nextProps.fields),
            });

            return false;
        }

        return true;
    };

    public validate = (): boolean => {
        let isValid = true;
        const newState = { ...this.state.fields };

        Object.entries(this.state.fields).forEach(([id, field]) => {
            const error = Validation.validate(field.value || '', field.validators);
            if (error) {
                field.error = error;
                newState[id] = field;

                isValid = false;
            }
        });

        this.setState({ fields: newState });

        return isValid;
    };

    public getData = (): FormValues => {
        return Object.entries(this.state.fields).reduce<FormValues>((plainMap, [id, state]) => {
            const value = state.inputMaskInstance ? state.inputMaskInstance.unmaskedValue : state.value;
            plainMap[id] = value.trim();

            return plainMap;
        }, {});
    };

    public setError = (gqlError: GQLErrorType): void => {
        if (!gqlError) {
            return;
        }

        if (!gqlError.isValidationError()) {
            return;
        }

        const newState = { ...this.state.fields };
        gqlError.getErrors().forEach((error) => {
            const field = this.state.fields[error.field];
            if (!field) {
                return;
            }

            field.error = { message: error.message };

            newState[error.field] = field;
        });

        this.setState({ fields: newState });
    };

    private buildFieldsState = (initial: FillFormFields): FillFormState['fields'] => {
        return initial.reduce<FillFormState['fields']>((state, section) => {
            section.fields.forEach((field) => {
                let value = '';

                if (FieldStorage.isRegularField(field.id)) {
                    value = FieldStorage.get(field.id);

                    if (!value && field.id === EMAIL_ID) {
                        value = this.props.savedDocuments[EMAIL_ID];
                    }
                    if (!value && field.id === DOCUMENT_ID) {
                        value = this.props.savedDocuments[DOCUMENT_ID];
                    }
                    if (!value && field.id === PHONE_ID) {
                        value = this.props.savedDocuments[PHONE_ID];
                    }
                    if (!value && field.id === FULL_ADDRESS_ID) {
                        value = this.props.savedDocuments[FULL_ADDRESS_ID];
                    }
                    if (!value && field.id === FULL_BILLING_ADDRESS_ID) {
                        value = this.props.savedDocuments[FULL_BILLING_ADDRESS_ID];
                    }
                }

                if (field.formatter) {
                    value = field.formatter(value);
                }

                {
                    state[field.id] = {
                        value,
                        error: undefined,
                        validators: field.validators,
                    };
                }
            });

            return state;
        }, {});
    };

    private getDataId(e: React.SyntheticEvent<HTMLElement>): FormId {
        return e?.currentTarget?.dataset.id || '';
    }

    private handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const id = this.getDataId(e);
        const field = this.state.fields[id];

        if (!field) {
            return;
        }

        field.value = e?.currentTarget?.value;
        field.error = undefined;

        const newState = { ...this.state.fields };
        newState[id] = field;
        this.setState({ fields: newState });
    };

    private handleBlur = (e: ChangeEvent<HTMLInputElement>) => {
        const id = this.getDataId(e);
        const field = this.state.fields[id];

        field.error = Validation.validate(e.currentTarget.value, field.validators);

        if (!field.error && isRegularField(id)) {
            FieldStorage.set(id, field.value);
        } else if (field.error) {
            FieldStorage.clear(id);
        }

        const newState = { ...this.state.fields };
        newState[id] = field;
        this.setState({ fields: newState });
    };

    private handleMaskSet = (id: FormField['id'], mask: InputMask<{ mask: any }>): void => {
        const field = this.state.fields[id];
        field.inputMaskInstance = mask;

        const newState = { ...this.state.fields };
        newState[id] = field;
        this.setState({ fields: newState });
    };

    private handleHintClick = (id: FormId) => {
        this.setState({ hint: getHintType(id) });
    };

    private handleCloseHint = () => {
        this.setState({ hint: undefined });
    };

    public render() {
        return (
            <div>
                {
                    this.props.fields
                        .map((section) => (
                            <SectionForm
                                id={section.id}
                                key={section.title}
                                title={section.title}
                                onClick={hasHint(section.id) ? () => this.handleHintClick(section.id) : undefined}
                            >
                                {
                                    section.fields.map((field) => (
                                        <UI.InputWrapper key={field.id}>
                                            <FormInput
                                                data-id={field.id}
                                                title={field.title}
                                                icon={hasHint(field.id) ? HintFilled : undefined}
                                                mask={field.mask}
                                                error={this.state.fields[field.id].error?.message}
                                                value={this.state.fields[field.id].value}
                                                type={field.type}
                                                onChange={this.handleChange}
                                                onBlur={this.handleBlur}
                                                onMaskSet={this.handleMaskSet}
                                                onIconClick={() => this.handleHintClick(field.id)}
                                            />
                                        </UI.InputWrapper>
                                    ))
                                }
                            </SectionForm>
                        ))
                }

                <Hint
                    country={this.props.country}
                    type={this.state.hint}
                    onClose={this.handleCloseHint}
                />
            </div>
        );
    }
}

export default FillForm;
